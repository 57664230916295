import { AIControlsState, ControlsDataPoint, GlobalControlsStatus } from './types'
import { GetterTree } from 'vuex'
import { RootState } from '../types'

export default {
  controlsDataPointByTag: (state: AIControlsState) => (tagKey: string, tagValue: string): ControlsDataPoint | null => {
    for (const dataPoint of (state.dataPoints || [])) {
      for (const tag of (dataPoint.dataPoint.tags || [])) {
        if (tag.key === tagKey && tag.value === tagValue) {
          return dataPoint
        }
      }
    }
    return null
  },

  getControlsStatus: (state: AIControlsState): GlobalControlsStatus | null => {
    return state.controlsStatus
  },

  getDataPoints: (state: AIControlsState): ControlsDataPoint[] | null => {
    return state.dataPoints
  },

  isLoadingControls: (state: AIControlsState): boolean => state.loadingControlsStatus,

  isLoadingDataPoints: (state: AIControlsState): boolean => state.loadingDataPoints,

} as GetterTree<AIControlsState, RootState>
