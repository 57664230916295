import { ControlsAppStatus } from '@aedifion.io/pinia-aedifion-api-stores'

/**
 * Roughly describes how a status code can be interpreted.
 * - `active` is `true` if the app is currently running.
 * - `disabled` is `true` if a new start/stop request should not be sent at the
 * moment.
 * - `hidden` is `true` if the app should not be shown at all.
 * - `indeterminate` is `true` if the value of `active` cannot really be
 * determined with confidence.
 * - `pending` is `true` if it should be communicated to the user that some
 * action is being performed on the app that might cause a change of its running
 * status.
 * - `transient` is `true` if the current state can be expected to change
 * without any action from the user.
 */
export type StatusCodeMeanings = {
  active: boolean,
  disabled: boolean,
  hidden: boolean,
  indeterminate: boolean,
  pending: boolean,
  transient: boolean,
}

const STATUS_CODE_MEANINGS: Record<number, StatusCodeMeanings | undefined> = {
  // 1XX

  [ControlsAppStatus.CREATED]: { // 100
    active: false,
    disabled: true,
    hidden: false,
    indeterminate: false,
    pending: false,
    transient: true,
  },
  [ControlsAppStatus.CREATE_FAILED]: { // 101
    active: false,
    disabled: true,
    hidden: false,
    indeterminate: false,
    pending: false,
    transient: false,
  },
  [ControlsAppStatus.READY]: { // 102
    active: false,
    disabled: false,
    hidden: false,
    indeterminate: false,
    pending: false,
    transient: false,
  },

  // 2XX

  [ControlsAppStatus.RUN_REQUESTED]: { // 200
    active: false,
    disabled: true,
    hidden: false,
    indeterminate: false,
    pending: true,
    transient: true,
  },
  [ControlsAppStatus.RUN_FAILED]: { // 201
    active: false,
    disabled: false,
    hidden: false,
    indeterminate: false,
    pending: false,
    transient: false,
  },
  [ControlsAppStatus.RUNNING]: { // 202
    active: true,
    disabled: false,
    hidden: false,
    indeterminate: false,
    pending: false,
    transient: false,
  },
  [ControlsAppStatus.RUN_INITIATED]: { // 203
    active: false,
    disabled: false,
    hidden: false,
    indeterminate: false,
    pending: true,
    transient: true,
  },

  // 3XX

  [ControlsAppStatus.STOP_REQUESTED]: { // 300
    active: true,
    disabled: true,
    hidden: false,
    indeterminate: false,
    pending: true,
    transient: true,
  },
  [ControlsAppStatus.STOP_FAILED]: { // 301
    active: true,
    disabled: false,
    hidden: false,
    indeterminate: false,
    pending: false,
    transient: false,
  },
  [ControlsAppStatus.STOPPED]: { // 302
    active: false,
    disabled: false,
    hidden: false,
    indeterminate: false,
    pending: false,
    transient: false,
  },
  [ControlsAppStatus.STOPPED_MAINTENANCE]: { // 303
    active: false,
    disabled: true,
    hidden: false,
    indeterminate: false,
    pending: false,
    transient: true,
  },
  [ControlsAppStatus.DIED]: { // 304
    active: false,
    disabled: false,
    hidden: false,
    indeterminate: false,
    pending: false,
    transient: false,
  },
  [ControlsAppStatus.STOP_INITIATED]: { // 305
    active: true,
    disabled: false,
    hidden: false,
    indeterminate: false,
    pending: true,
    transient: true,
  },
  [ControlsAppStatus.STOP_MAINTENANCE_INITIATED]: { // 306
    active: true,
    disabled: true,
    hidden: false,
    indeterminate: false,
    pending: true,
    transient: true,
  },
  [ControlsAppStatus.STOPPED_PEER_DIED]: { // 307
    active: false,
    disabled: false,
    hidden: false,
    indeterminate: false,
    pending: false,
    transient: false,
  },

  // 4XX

  [ControlsAppStatus.UPDATE_REQUESTED]: { // 400
    active: false,
    disabled: true,
    hidden: false,
    indeterminate: true,
    pending: false,
    transient: true,
  },
  [ControlsAppStatus.UPDATE_FAILED]: { // 401
    active: false,
    disabled: true,
    hidden: false,
    indeterminate: true,
    pending: false,
    transient: false,
  },

  // 5XX

  [ControlsAppStatus.DELETE_REQUESTED]: { // 500
    active: false,
    disabled: true,
    hidden: false,
    indeterminate: true,
    pending: false,
    transient: true,
  },
  [ControlsAppStatus.DELETE_FAILED]: { // 501
    active: false,
    disabled: false,
    hidden: false,
    indeterminate: true,
    pending: false,
    transient: false,
  },
  [ControlsAppStatus.DELETED]: { // 502
    active: false,
    disabled: true,
    hidden: true,
    indeterminate: true,
    pending: false,
    transient: false,
  },
  [ControlsAppStatus.DELETE_INITIATED]: { // 503
    active: false,
    disabled: true,
    hidden: false,
    indeterminate: true,
    pending: false,
    transient: true,
  },
}

/**
 * Returns an object whose properties roughly reflect how a status code can be
 * interpreted.
 *
 * See `StatusCodeMeanings` for a full description of those interpretations.
 *
 * @param statusCode the status code that we wish to "inspect".
 *
 * @example
 * getStatusCodeMeanings(202)
 */
export function getStatusCodeMeanings (statusCode: number): StatusCodeMeanings {
  return STATUS_CODE_MEANINGS[statusCode] ?? {
    active: false,
    disabled: true,
    hidden: true,
    indeterminate: true,
    pending: false,
    transient: false,
  }
}

/**
 * Returns the value of a single interpretation that can be made of a status
 * code.
 *
 * See `StatusCodeMeanings` for a full description and list of those
 * interpretations.
 *
 * @param meaning the property of the status code that we wish to "inspect".
 * @param statusCode the status code that we wish to "inspect".
 *
 * @example
 * getStatusCodeMeaning('active', 202) // returns true
 */
export function getStatusCodeMeaning <T extends keyof StatusCodeMeanings> (meaning: T, statusCode: number): StatusCodeMeanings[T] {
  return getStatusCodeMeanings(statusCode)[meaning]
}

/**
 * Returns a list of all status codes that can be interpreted with a certain
 * value. If the value is not specified, returns a list of all status codes
 * where the interpretation is truthy.
 *
 * See `StatusCodeMeanings` for a full description and list of those
 * interpretations.
 *
 * @param meaning the property that we wish to group by.
 * @param [value] the value that this property should have for the status code
 * to be included. If not specified, a status code will be included if its
 * property is truthy.
 *
 * @example
 * getAllStatusCodesWithMeaning('active', true)
 * // returns [202, 300, 301, 305, 306]
 */
export function getAllStatusCodesWithMeaning <T extends keyof StatusCodeMeanings> (meaning: T, value?: StatusCodeMeanings[T]): ControlsAppStatus[] {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return Object.entries(STATUS_CODE_MEANINGS).filter(([_code, meanings]) => {
    if (meanings === undefined) {
      return false
    }
    if (value !== undefined) {
      return meanings[meaning] === value
    }
    return meanings[meaning]
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  }).map(([code, _meanings]) => {
    return Number(code)
  })
}

/**
 * Mapped shortcodes to their controls algorithm.
 */
const ALGORITHM_SHORTCODE_MAPPINGS: { [algorithm: string]: string } = {
  custom: 'cust',
  heating_curve: 'curv',
  schedule: 'shed',
}

/**
 * Returns the algorithm short code for the passed algorithm.
 * @param algorithm Algorithm name.
 * @returns Short code of the algorithm if found, otherwise returns the passed algorithm.
 */
export function getAlgorithmShortcode (algorithm: string): string {
  return ALGORITHM_SHORTCODE_MAPPINGS[algorithm] || algorithm
}

const AVAILABLE_ALGORITHM_SHORTCODE_GRADIENTS = 8

export function getGradientNumberForIndex (index: number): number {
  return index % AVAILABLE_ALGORITHM_SHORTCODE_GRADIENTS + 1
}

export function getRandomGradientNumber (): number {
  return Math.floor(Math.random() * AVAILABLE_ALGORITHM_SHORTCODE_GRADIENTS) + 1
}
