import { CompanyRole } from '@aedifion.io/aedifion-api'
import { computed } from 'vue'
import { hasCompanyRole } from '@/utils/helpers/roles'
import { useUserStore } from '@/stores/user'

export default function useIsCompanyAdmin () {
  const userStore = useUserStore()

  const companyRoles = computed<CompanyRole[]>(() => userStore.companyRoles)
  const isCompanyAdmin = computed<boolean>(() => hasCompanyRole('admin', companyRoles.value))

  return { isCompanyAdmin }
}
