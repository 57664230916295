import '@/scss/tailwind.css'
import '@/scss/main.scss'
import '@/utils/helpers/registerServiceWorker'
import { createApp, h } from 'vue'
import App from './App.vue'
import { browserTracingIntegration } from '@sentry/vue'
import i18n from './i18n'
import { Integration } from '@sentry/types'
import Notifications from '@kyvg/vue3-notification'
import pinia from './stores'
import { registerOfflineDetection } from './services/offlineDetector'
import router from './router'
import { setupSentry } from './services/sentry'
import { startCookieConsent } from './cookieconsent'
import store from './vuex'
import { useProductFruits } from './composables/useProductFruits'
import vuetify from './plugins/vuetify'
import VuexQueryUpdaterPlugin from '@/vuex/plugins/QueryUpdater/queryUpdater'

const sentryIntegrations: Integration[] = [
  browserTracingIntegration({
    router,
  }),
]

startCookieConsent()
registerOfflineDetection()

VuexQueryUpdaterPlugin(store)

export const app = createApp(
  {
    setup () {
      useProductFruits()
    },
    render: () => h(App),
  })
  .use(router)
  .use(store)
  .use(pinia)
  .use(vuetify)
  .use(i18n)
  .use(Notifications)

router.isReady().then(() => {
  app.mount('#app')
  setupSentry(sentryIntegrations)
})
