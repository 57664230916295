<script setup lang="ts">
import { ColumnHeader, RowData, SortDirection, Width } from '@/components/SummarizedContent/types'
import { computed, ref } from 'vue'
import CompanyRoleSelector from '@/views/Administration/Users/CompanyRoleSelector.vue'
import { copyStringToClipboard } from '@/utils/helpers/clipboard'
import SummarizedTable from '@/components/SummarizedTable.vue'
import { useAdministrationStore } from '@/stores/views/Administration'
import { useAdministrationUsersStore } from '@/stores/views/Administration/users'
import { useDeleteUser } from '../composables/useDeleteUser'
import { useI18n } from 'vue-i18n'
import UserOptionsBtn from '@/views/Administration/Users/UserOptionsBtn.vue'
import { useUserStore } from '@/stores/user'

type Keys = 'name' | 'companyRoles' | 'projects' | 'options'

const userStore = useUserStore()
const administrationStore = useAdministrationStore()
const administrationUsersStore = useAdministrationUsersStore()
const { t } = useI18n()

const emit = defineEmits<{
  (e: 'edit-user', userId: number): void
}>()

const loading = computed(() => {
  return administrationStore.loading
})

const headers = ref<ColumnHeader<Keys>[]>([{
  key: 'name',
  text: t('columns.name'),
  width: Width.Grow,
}, {
  key: 'companyRoles',
  text: t('columns.roles'),
  width: Width.MaxContent,
}, {
  key: 'projects',
  text: t('columns.projects'),
  width: Width.Grow,
}, {
  key: 'options',
  text: '',
  width: Width.MaxContent,
}])

const rows = computed<RowData<Keys>[]>(() => {
  return administrationUsersStore.filteredUsers.map((user) => {
    const isCurrentUser = user.id !== undefined && userStore.user?.user?.id === user.id

    const projects: string[] = administrationStore.projects.filter((project) => {
      return project.roles?.some((role) => {
        return role.id !== undefined && user.project_roles?.includes(role.id)
      })
    }).slice().sort((projectA, projectB) => {
      return projectA.name.localeCompare(projectB.name)
    }).map((project) => {
      return project.name
    })

    return {
      companyRoles: {
        custom: {
          id: user.id,
          loading: user.isLoading,
          roles: user.company_roles,
        },
        text: '',
      },
      name: {
        custom: {
          avatar: user.avatar_url,
          email: user.email!, // this field is actually required in the table schema
          id: user.id,
          isCurrentUser,
        },
        text: [user.firstName, user.lastName].filter((namePart) => {
          return !!namePart
        }).join(' '),
      },
      options: {
        custom: {
          id: user.id,
          loading: user.isLoading,
        },
        text: '',
      },
      projects: {
        text: projects.join(', '),
      },
    }
  })
})

function copyToClipboard (text: string | undefined) {
  if (text) {
    copyStringToClipboard(text)
  }
}

const { setupUserToDelete } = useDeleteUser()
</script>

<template>
  <div>
    <SummarizedTable
      v-if="!loading && rows.length"
      :default-sorting="{
        direction: SortDirection.ASC,
        key: 'name'
      }"
      :headers="headers"
      :rows="rows"
      :sticky-headers="false"
    >
      <template #cell.name="{ data }">
        <div class="d-flex align-center overflow-hidden">
          <v-img
            :alt="t('avatar_alt', { person: data.text })"
            aspect-ratio="1"
            cover
            class="aedifion-border neutral-lighten1 rounded-circle mr-2"
            :src="data.custom.avatar ?? '/img/dummy-image.jpg'"
            width="42px"
          />
          <div class="d-flex flex-column align-start overflow-hidden">
            <div class="user-name-wrapper d-flex align-baseline text-truncate">
              <router-link
                class="user-link text-body-1 font-weight-semibold text-decoration-none text-truncate"
                :to="`/administration/users/${data.custom.id}`"
              >
                {{ data.text }}
              </router-link>
              <span
                v-if="data.custom.isCurrentUser"
                class="text-legend font-weight-medium text-neutral-darken1 ml-1"
              >
                (You)
              </span>
            </div>
            <v-tooltip location="top">
              <template #activator="{ props }">
                <span

                  class="user-email text-subtitle-1 text-neutral-darken1 text-truncate"
                  v-bind="props"
                  @click="copyToClipboard(data.custom.email)"
                >
                  {{ data.custom.email }}
                </span>
              </template>
              {{ t('copy_to_clipboard') }}
            </v-tooltip>
          </div>
        </div>
      </template>
      <template #cell.companyRoles="{ data }">
        <div class="d-flex align-center">
          <CompanyRoleSelector
            :company-roles-ids="data.custom.roles"
            :disabled="data.custom.loading"
            :user-id="data.custom.id"
          />
        </div>
      </template>
      <template #cell.options="{ data }">
        <div class="d-flex align-center mx-n2">
          <UserOptionsBtn
            :disabled="data.custom.loading"
            :user-id="data.custom.id"
            @delete-option-click="setupUserToDelete(data.custom.id)"
            @edit-option-click="emit('edit-user', data.custom.id)"
          />
        </div>
      </template>
    </SummarizedTable>
    <v-row
      v-if="loading"
      align="center"
      class="mt-16"
      justify="center"
    >
      <v-progress-circular
        indeterminate
        size="80"
      />
    </v-row>
    <v-sheet
      v-else-if="rows.length < 1"
      class="text-center mt-12 py-16"
    >
      <h6 class="text-h6">
        {{ t('no_search_result') }}
      </h6>
    </v-sheet>
  </div>
</template>

<style scoped lang="sass">
.user-email
  cursor: pointer

.user-email, .user-name-wrapper
  max-width: 100%

.user-link
  color: rgb(var(--v-theme-neutral-darken4))

.user-link:hover
  color: rgb(var(--v-theme-primary-darken2))
</style>

<i18n lang="json" locale="de">
{
  "avatar_alt": "Avatar von {person}",
  "columns": {
    "name": "Name",
    "projects": "Projekte",
    "roles": "Firmenrolle"
  },
  "copy_to_clipboard": "Die E-Mail-Adresse in die Zwischenablage kopieren",
  "no_search_result": "Kein Benutzer entspricht diesen Filtern"
}
</i18n>
<i18n lang="json" locale="en">
{
  "avatar_alt": "{person}’s avatar",
  "columns": {
    "name": "Name",
    "projects": "Projects",
    "roles": "Company role"
  },
  "copy_to_clipboard": "Copy the email address to clipboard",
  "no_search_result": "No user matches these filters"
}
</i18n>
