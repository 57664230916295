<script setup lang="ts">
import alphanumericIds from '@theme/availableComponentIcons'
import { computed } from 'vue'

type Props = {
  alphanumericId?: string,
  color?: string,
  size: string | number
}

const props = withDefaults(defineProps<Props>(), {
  color: 'primary-darken2',
})

const icon = computed(() => {
  if (
    props.alphanumericId &&
    alphanumericIds.includes(props.alphanumericId)
  ) {
    const lowerCaseAlphanumericId = props.alphanumericId.toLowerCase()
    if (lowerCaseAlphanumericId === 'fan') {
      // For some reason the icon with name 'fak fa-fan' doesn't work. This is a workaround until we find out why. Fontawesome has been contacted.
      return 'fak fa-fan-alt'
    } else if (lowerCaseAlphanumericId === 'b') {
      // Font awesome doesn't like single-letter names
      return 'fak fa-buil'
    } else if (lowerCaseAlphanumericId === 's') {
      // Font awesome doesn't like single-letter names
      return 'fak fa-sys'
    } else {
      return `fak fa-${lowerCaseAlphanumericId.replaceAll('_', '-')}`
    }
  }
  return 'fak fa-default'
})
</script>

<template>
  <v-icon
    :class="icon"
    :color="props.color"
    :size="props.size"
  />
</template>
