<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { formatSubAndSuper, formatValue } from '@/filters/formatting'
import { KPIResult, NewTask } from '@aedifion.io/aedifion-api'
import AnalysisHistory from './AnalysisHistory.vue'
import type { ComponentInProjectListItemData } from '@/vuex/components_in_project/types'
import { ExtendedAnalysisResult } from '@/vuex/analysis_instances/types'
import KPITable from './KPITable.vue'
import { storeToRefs } from 'pinia'
import TaskViewDialog from '../../Checklist/Tasks/TaskViewDialog.vue'
import { useAdministrationStore } from '@/stores/views/Administration'
import { useAppStore } from '@/stores/app'
import { useChecklistStore } from '@/stores/views/Optimization/Checklist'
import { useI18n } from 'vue-i18n'
import useIsCompanyAdmin from '@/composables/useIsCompanyAdmin'
import useIsReadonly from '@/composables/useIsReadOnly'
import { useRoute } from 'vue-router'
import vuexStore from '@/vuex'
import { whitelabelColor } from '@/filters/colors'

defineOptions({
  inheritAttrs: false,
})

interface Props {
  functionName: string;
  latestResultId: string | null;
}

defineProps<Props>()

export type KPIResultWithStringValue = {
  name: string;
  unit: string;
  value: string;
}

const { t } = useI18n()

const analysisResult = computed<ExtendedAnalysisResult|null>(() => vuexStore.state.analysis_instances.analysisResult as ExtendedAnalysisResult|null)

const analysisResultId = computed<string|null>(() => analysisResult.value?.result_id ?? null)

const color = computed<string|null>(() => analysisResult.value?.signal_color?.toString() ?? null)

const interpretation = computed<string|null>(() => analysisResult.value?.interpretation ?? null)

const kpis = computed<KPIResultWithStringValue[]>(() => (analysisResult.value?.kpi ?? [])
  .map((kpi: KPIResult): KPIResultWithStringValue => {
    let value
    if (typeof kpi.value === 'string') {
      value = kpi.value
    } else {
      value = formatValue(kpi.value, { fallbackValue: t('kpi.no_data') as string })
    }
    return {
      name: kpi.name!,
      unit: kpi.unit!,
      value,
    }
  }) as KPIResultWithStringValue[])

const loading = computed<boolean>(() => vuexStore.state.analysis_instances.loadingAnalysisResult)

const recommendations = computed<string[]>(() => {
  if (analysisResult.value?.recommendation?.length === 0 && color.value === 'green') return [t('plant_runs_well') as string]
  else if (analysisResult.value?.recommendation?.length === 0) return [t('no_recommendations') as string]
  else return analysisResult.value?.recommendation as string[]
})

// #region TASK CREATION
const checklistStore = useChecklistStore()
const appStore = useAppStore()
const { isCompanyAdmin } = useIsCompanyAdmin()
const { isReadOnly } = useIsReadonly()
const administrationStore = useAdministrationStore()

// #region COMPONENT SELECTION
const route = useRoute()
const currentComponentId = ref(Number(route.params.componentInProjectId))
const selectedComponent = computed(() => vuexStore.getters['components_in_project/componentsInProjectListItems'].find((component: ComponentInProjectListItemData) => component.id === currentComponentId.value))
// #endregion

const isTaskDialogOpen = ref(false)

const { shortCompanyUsersWithTitle } = storeToRefs(administrationStore)

async function onCreateTask (task: NewTask) {
  isTaskDialogOpen.value = false
  await checklistStore.postTask({
    body: task,
    projectId: appStore.projectId,
  })
}

watch(() => isTaskDialogOpen.value, (isOpen) => {
  if (isOpen && !administrationStore.users?.length && !isReadOnly.value) {
    administrationStore.fetchCompanyWithContext()
  }
})
// #endregion

</script>

<template>
  <v-sheet
    class="pa-4"
    data-cy="analysisResultSheet"
  >
    <AnalysisHistory
      :is-read-only="isReadOnly"
      :latest-result-id="latestResultId"
      @add-task="isTaskDialogOpen = true"
    />
    <v-alert
      :class="[`full-width px-4 py-2 mb-2 bg-${whitelabelColor(color!)}-lighten4 text-${whitelabelColor(color!)}-darken2 `]"
      data-cy="analysisResultInterpretation"
      :icon="false"
      variant="outlined"
      :type="whitelabelColor(color!)"
    >
      <v-icon
        class="mr-4"
        :color="`${whitelabelColor(color!)}-darken2`"
      >
        fa:fal fa-info-circle
      </v-icon>
      {{ interpretation }}
    </v-alert>
    <v-row>
      <v-col
        cols="12"
      >
        <v-card
          class="fill-height"
          data-cy="analysisResultSheetRecommendations"
          rounded
        >
          <v-card-title>
            <v-skeleton-loader
              v-if="loading"
              class="mt-2"
              height="22"
              :loading="loading"
              min-width="75%"
              type="text"
            />
            <div v-else>
              {{ t('recommendations') }}
            </div>
          </v-card-title>
          <v-card-text>
            <div
              v-if="loading"
              class="mb-3 mt-2"
            >
              <v-skeleton-loader
                height="15"
                :loading="loading"
                type="text"
              />
              <v-skeleton-loader
                height="15"
                :loading="loading"
                type="text"
              />
              <v-skeleton-loader
                height="15"
                :loading="loading"
                type="text"
              />
              <v-skeleton-loader
                height="15"
                :loading="loading"
                type="text"
              />
            </div>
            <ul class="recommendations pl-4">
              <li
                v-for="recommendation, i in recommendations"
                :key="`recommendation_${i}`"
                v-html="formatSubAndSuper(recommendation)"
              />
            </ul>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        class="mt-0 pt-0"
        cols="12"
      >
        <KPITable
          :items="kpis"
          :loading="loading"
        />
      </v-col>
    </v-row>
  </v-sheet>
  <TaskViewDialog
    v-if="isTaskDialogOpen"
    :task="null"
    :analysis-result-id="analysisResultId"
    :company-users="shortCompanyUsersWithTitle"
    :selected-component="selectedComponent"
    :value="isTaskDialogOpen"
    :is-company-admin="isCompanyAdmin"
    :is-read-only="isReadOnly"
    @close="isTaskDialogOpen = false"
    @create-task="onCreateTask"
  />
</template>

<style lang="sass" scoped>
.full-width
  max-width: none
.recommendations li:not(:first-child)
    margin-top: 4px
</style>

<i18n lang="json" locale="de">
  {
    "recommendations": "Handlungsempfehlungen",
    "plant_runs_well": "Anlage läuft gut, keine Handlungsempfehlungen.",
    "no_recommendations": "Keine Handlungsempfehlungen.",
    "kpi": {
      "no_data": "Kein Wert"
    }
  }
</i18n>
<i18n lang="json" locale="en">
  {
    "recommendations": "Recommendations",
    "plant_runs_well": "Plant running well, no recommendations for action.",
    "no_recommendations": "No recommendations for action.",
    "kpi": {
      "no_data": "No value"
    }
  }
</i18n>
