<template>
  <v-card
    class="pa-6 mx-auto hoverable-bordered"
    :to="projectLink"
  >
    <v-skeleton-loader
      v-if="isLoading"
      type="image"
    />
    <v-img
      v-else
      :aspect-ratio="16 / 9"
      class="rounded mouse-hover"
      height="194"
      cover
      lazy-src="/img/dummy-image.jpg"
      :src="project.avatar || '/img/dummy-image.jpg'"
    />

    <v-card-title
      class="card-title text-h5 text-truncate mouse-hover px-0 pb-1 mt-2"
      style="display: block"
    >
      <v-skeleton-loader
        v-if="isLoading"
        type="heading"
      />
      <span
        v-else
        v-text="project.name"
      />
    </v-card-title>

    <v-card-subtitle class="card-description pa-0 mt-0">
      <v-skeleton-loader
        v-if="isLoading"
        class="pt-2"
        type="sentences"
      />
      {{ isLoading ? '' : project.description }}
    </v-card-subtitle>

    <v-card-actions class="pt-3 px-0">
      <ProjectStatus :project-id="project.id" />
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { isAssetOverviewSetUp } from '@/utils/helpers/viewStatus'
import { ProjectCardData } from '@/vuex/projects/types'
import ProjectStatus from '@/views/Portfolio/ProjectStatus.vue'
import { RouteLocation } from 'vue-router'

export default defineComponent({
  name: 'ProjectCard',

  components: {
    ProjectStatus,
  },

  props: {
    project: {
      required: true,
      type: Object as PropType<ProjectCardData>,
    },
  },

  computed: {
    isLoading (): boolean {
      return this.$store.getters['projects/isLoading']
    },

    projectLink (): RouteLocation {
      return this.$router.resolve({
        name: isAssetOverviewSetUp(this.project.id) ? 'asset-overview' : 'optimization-optimizations',
        params: {
          project: this.project.id.toString(),
        },
      })
    },
  },

  methods: {
    navigateToRoute (route: string) {
      this.$router.push(route)
    },
  },
})
</script>

<style lang="sass" scoped>
.card-description
  height: 42px
  display: -webkit-box
  overflow: hidden
  -webkit-line-clamp: 2
  -webkit-box-orient: vertical
  white-space: normal !important

.v-skeleton-loader__button
  width: 130px

.mouse-hover:hover
  cursor: pointer
</style>
