<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'
import { useDisplay } from 'vuetify'

const display = useDisplay()

// @ts-ignore
const maxAvailableColumns = computed(() => {
  if (display.smAndDown.value) return 1
  if (display.md.value) return 2
  if (display.lg.value) return 3
  return 4
})

// Adds a custom CSS class to each child element of component.
const vChildrenCss = {
  mounted (el: HTMLElement) {
    const children = el.children
    for (const element of children) {
      element.classList.add('masonry-grid-item')
    }
  },
}
// #region ADD SAFARI FALLBACK GRID
const isSafari = ref(false)

function detectSafari () {
  const userAgent = navigator.userAgent.toLowerCase()
  return (userAgent.indexOf('safari') !== -1) && (userAgent.indexOf('chrome') === -1)
}

onMounted(() => {
  isSafari.value = detectSafari()
})
// #endregion
</script>

<template>
  <div
    v-children-css
    :class="['tw-w-full masonry-grid', { 'fallback-grid': isSafari }]"
  >
    <slot />
  </div>
</template>

<style lang="sass">
/* This style block was left unscoped on purpose so that its children can access the masonry-grid-item class */
.masonry-grid
  columns: v-bind(maxAvailableColumns) auto
  column-gap: 24px

  // Fallback for browsers that don't support columns (3 was chosen as the default number of columns)
.fallback-grid
  display: grid
  grid-template-columns: repeat(v-bind(maxAvailableColumns), 1fr)
  gap: 24px
  .masonry-grid-item
    margin-bottom: 0

.masonry-grid-item
  width: 100%
  break-inside: avoid
  margin-bottom: 24px
</style>
