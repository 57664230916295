<script setup lang="ts">
import ConfirmationDialog from '@/components/ConfirmationDialog.vue'
import { useDeleteUser } from '../composables/useDeleteUser'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const { deleteUser, showDeletionDialog, userIdToDelete, userToDeleteFullName } = useDeleteUser()
</script>

<template>
  <ConfirmationDialog
    accept-button-color="error"
    :dialog-width="480"
    :title="t('deletion_dialog.title')"
    :text="t('deletion_dialog.text')"
    :required-input="userToDeleteFullName"
    :value="showDeletionDialog"
    accept-button-text-key="actions.delete"
    @confirmation-dialog:cancel="showDeletionDialog = false; userIdToDelete = null"
    @confirmation-dialog:accept="deleteUser"
  >
    <template #warning>
      <p class="text-body-1">
        {{ t('deletion_dialog.text') }}
      </p>
    </template>
  </ConfirmationDialog>
</template>

<i18n lang="json" locale="de">
  {
    "deletion_dialog": {
      "title": "Benutzer löschen",
      "text": "Das Löschen eines Benutzers kann nicht rückgängig gemacht werden. Wenn ein Benutzer gelöscht wird, muss er manuell wieder hinzugefügt werden."
    }
  }
  </i18n>
<i18n lang="json" locale="en">
  {
    "deletion_dialog": {
      "title": "Delete User",
      "text": "Deleting a user cannot be undone. If a user is deleted, it has to be manually re-added."
    }
  }
</i18n>
