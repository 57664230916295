<script lang="ts" setup>
import { CompanyRole, CompanyWithContextUsers, Role } from '@aedifion.io/aedifion-api'
import { ProjectRoleId, useAdministrationUsersStore, UserRolesUpdatePayload } from '@/stores/views/Administration/users'
import { computed } from 'vue'
import ProjectsTable from './ProjectsTable.vue'
import { useAdministrationStore } from '@/stores/views/Administration/index'
import { useI18n } from 'vue-i18n'
import UserCard from './UserCard.vue'
import { useRoute } from 'vue-router'

const { t } = useI18n()
const route = useRoute()
const administrationStore = useAdministrationStore()
const administrationUsersStore = useAdministrationUsersStore()

const selectedUser = computed<CompanyWithContextUsers & { isLoading?: boolean }>(() => {
  const userIdParameter = parseInt(route.params.user as string)
  return administrationStore.users.find((user) => user.id === userIdParameter)!
})

const selectedUsersFullName = computed<string>(() => {
  return `${selectedUser.value.firstName} ${selectedUser.value.lastName}`
})

const companyRole = computed<CompanyRole|null>(() => {
  if (selectedUser.value.company_roles!.length === 0) return null
  return administrationStore.company!.roles!.find((role: CompanyRole) => role.id === selectedUser.value.company_roles![0])!
})

const loading = computed<boolean>(() => {
  return selectedUser.value.isLoading ?? false
})

const usersProjectRoles = computed<Role[]>(() => {
  return administrationStore.projects.flatMap((project) => {
    return project.roles!.filter((role) => {
      return selectedUser.value.project_roles!.includes(role.id!)
    }).map(role => {
      return {
        ...role,
        project_id: project.id,
      }
    })
  })
})

const userProjectRoleIds = computed<ProjectRoleId[]>(() => {
  return usersProjectRoles.value.map((role: Role) => {
    return {
      projectId: role.project_id!,
      roleId: role.id!,
    } as ProjectRoleId
  })
})

const projectsAccess = computed<string>(() => {
  const projectIdSet = new Set<number>()
  usersProjectRoles.value.forEach((role: Role) => {
    projectIdSet.add(role.project_id!)
  })

  return projectIdSet.size.toString()
})

function onToggleRole (projectId: number, roleIdToAdd: number, roleIdToRemove: number) {
  const finalRoleArray = [...userProjectRoleIds.value.filter((usersProjectRole) => {
    return usersProjectRole.roleId !== roleIdToRemove
  }), {
    projectId,
    roleId: roleIdToAdd,
  }]

  const payload: UserRolesUpdatePayload = {
    projectRoles: {
      add: [{
        projectId,
        roleId: roleIdToAdd,
      }],
      final: finalRoleArray,
      remove: [{
        projectId,
        roleId: roleIdToRemove,
      }],
    },
    userId: selectedUser.value.id!,
  }

  administrationUsersStore.updateUserRoles(payload)
}
</script>

<template>
  <div class="fill-width">
    <h3 class="text-h3">
      <router-link
        class="text-neutral-darken1"
        to="/administration/users"
      >
        {{ t('users') }}
      </router-link> <span class="text-neutral-darken1">></span> <span class="text-neutral-darken4">{{ selectedUsersFullName }}</span>
    </h3>
    <div class="d-flex">
      <UserCard
        :user="selectedUser"
        :company-role="companyRole"
        :projects-access="projectsAccess"
      />
      <ProjectsTable
        class="flex"
        :users-project-roles="usersProjectRoles"
        :user-id="selectedUser.id"
        :loading="loading"
        @toggle-role="onToggleRole"
      />
    </div>
  </div>
</template>

<i18n locale="de">
{
  "users": "Benutzer"
}
</i18n>
<i18n locale="en">
{
  "users": "Users"
}
</i18n>
