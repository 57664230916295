<template>
  <v-sheet
    class="detailed-content-card pa-4 mx-n2 mt-10"
    rounded
  >
    <v-alert
      v-if="loading || (analysisResult && analysisResult.interpretation)"
      :icon="false"
      :class="[`full-width px-4 py-2 mb-2 bg-${colorName(notificationBarColor!)}-lighten4 text-${colorName(notificationBarColor!)}-darken2`]"
      variant="outlined"
      :type="colorName(notificationBarColor) as AlertType"
    >
      <v-skeleton-loader
        v-if="loading"
        loading
        min-width="90%"
        type="text"
      />
      <div
        v-else-if="analysisResult && analysisResult.interpretation"
        class="d-flex flex-row justify-start align-center"
      >
        <v-icon
          class="mr-4"
          :color="`${colorName(notificationBarColor)}-darken2`"
        >
          fa:fal fa-info-circle
        </v-icon>
        <span v-html="analysisResult ? formatSubAndSuper(analysisResult.interpretation) : ''" />
      </div>
    </v-alert>
    <v-row dense>
      <v-col cols="4">
        <AssetAnalysisSummary
          class="fill-height"
          :kpi-key="kpiKey"
          :loading="loading"
          :summary-kpi="summaryKpi"
          :title="analysisResult?.display_name ?? ''"
        />
      </v-col>
      <v-col cols="8">
        <AssetAnalysisRecommendations
          :analysis-result="analysisResult"
          class="fill-height"
          :kpi-key="kpiKey"
          :loading="loading"
        />
      </v-col>
      <v-col
        v-for="plot, index in supportedPlots"
        :key="index"
        cols="4"
      >
        <DetailedContentCardPlot
          :loading="loading"
          :plot="plot"
        />
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script lang="ts">
import { AnalysisResult, PlotsResult, SummaryKPIResult } from '@aedifion.io/aedifion-api'
import { defineComponent, PropType } from 'vue'
import DetailedContentCardPlot, { SUPPORTED_PLOT_TYPES } from './DetailedContentCardPlot.vue'
import AssetAnalysisRecommendations from './AssetAnalysisRecommendations.vue'
import AssetAnalysisSummary from './AssetAnalysisSummary.vue'
import { colorName } from '@/filters/colors'
import { formatSubAndSuper } from '@/filters/formatting'

export type AlertType = 'info' | 'success' | 'warning' | 'error'

export default defineComponent({
  name: 'DetailedContentCard',

  components: {
    AssetAnalysisRecommendations,
    AssetAnalysisSummary,
    DetailedContentCardPlot,
  },

  props: {
    analysisResult: {
      default: null,
      required: false,
      type: Object as PropType<AnalysisResult>,
    },

    kpiKey: {
      required: true,
      type: String as PropType<string>,
    },

    loading: {
      default: false,
      required: false,
      type: Boolean as PropType<boolean>,
    },

    summaryKpi: {
      required: false,
      type: Object as PropType<SummaryKPIResult>,
    },
  },

  computed: {
    notificationBarColor (): AnalysisResult.SignalColorEnum|string {
      if (this.loading || !this.analysisResult || !this.analysisResult.signal_color) {
        return 'info'
      } else {
        return this.analysisResult.signal_color
      }
    },

    supportedPlots (): PlotsResult[] {
      const allPlots: PlotsResult[] = ((this.analysisResult && this.analysisResult.plots) || [])

      return allPlots.filter((plot: PlotsResult) => SUPPORTED_PLOT_TYPES.includes(plot.type!))
    },
  },

  methods: {
    colorName,
    formatSubAndSuper,
  },
})
</script>

<style lang="sass" scoped>
.full-width
  max-width: none
.detailed-content-card
  .v-skeleton-loader__text.v-skeleton-loader__bone
    margin-bottom: 0 !important
</style>
