import { Alert, STATUS } from '@/services/alerta/types'
import { AlertsState } from './types'
import { MutationTree } from 'vuex'
import { ProjectValue } from '@/utils/types'

export default {
  ADD_PROJECT_INCIDENT_ALERTS: (state: AlertsState, projectIncidentAlerts: ProjectValue<Alert[]>) => {
    if (state.incidentAlerts[projectIncidentAlerts.projectId]) {
      state.incidentAlerts[projectIncidentAlerts.projectId].push(...projectIncidentAlerts.value)
    } else {
      state.incidentAlerts[projectIncidentAlerts.projectId] = projectIncidentAlerts.value
    }
  },

  ADD_PROJECT_STATUS_COUNTS: (state: AlertsState, projectStatusCounts: ProjectValue<Partial<Record<STATUS, number>>>) => {
    if (projectStatusCounts.projectId in state.statusCounts) {
      const statusCountsForProject = state.statusCounts[projectStatusCounts.projectId]
      for (const status in projectStatusCounts.value) {
        if (status in statusCountsForProject) {
          statusCountsForProject[status as STATUS]! += projectStatusCounts.value[status as STATUS]!
        } else {
          statusCountsForProject[status as STATUS] = projectStatusCounts.value[status as STATUS]
        }
      }
    } else {
      state.statusCounts[projectStatusCounts.projectId] = projectStatusCounts.value
    }
  },

  CLEAR_PROJECT_DATA: (state: AlertsState, projectId: number) => {
    delete state.incidentAlerts[projectId]
    delete state.statusCounts[projectId]
  },

  SET_LOADING_PROJECT_INCIDENT_ALERTS: (state: AlertsState, loading: ProjectValue<boolean>) => {
    if (loading.value) {
      state.loadingIncidentAlerts.push(loading.projectId)
    } else {
      const index = state.loadingIncidentAlerts.indexOf(loading.projectId)
      if (index > -1) {
        state.loadingIncidentAlerts.splice(index, 1)
      }
    }
  },
} as MutationTree<AlertsState>
