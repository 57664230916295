import { Alert, STATUS } from '@/services/alerta/types'
import { AlertsState } from './types'
import { GetterTree } from 'vuex'
import { RootState } from '../types'

export default {
  areIncidentAlertsOfProjectLoaded: (state: AlertsState) => (projectId: number): boolean => {
    return projectId in state.incidentAlerts
  },

  incidentAlertCountsOfProject: (state: AlertsState) => (projectId: number): Partial<Record<STATUS, number>>|null => {
    return state.statusCounts[projectId] ?? null
  },

  incidentAlertsOfProject: (state: AlertsState) => (projectId: number): Alert[]|null => {
    return state.incidentAlerts[projectId] ?? null
  },

  isLoadingAlertsOfProject: (state: AlertsState) => (projectId: number): boolean => {
    return state.loadingIncidentAlerts.includes(projectId)
  },
} as GetterTree<AlertsState, RootState>
