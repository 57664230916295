import { ControlsDataPoint } from './types'

export function sampleDataPoints (): ControlsDataPoint[] {
  return [
    {
      dataPoint: {
        dataPointID: 'controls_cost_savings',
        tags: [
          {
            _protected: false,
            id: 1045,
            key: 'controls_cockpit_savings',
            source: 'user',
            value: 'cost_savings',
          },
          {
            _protected: false,
            id: 1044,
            key: 'time_reference',
            source: 'user',
            value: '{"en": "Two-day average", "de": "Zwei-Tagesmittelwert"}',
          },
          {
            _protected: false,
            id: 1043,
            key: 'alternative_units',
            source: 'user',
            value: '{"en": "€/m^2/M", "de": "€/m^2/M"}',
          },
        ],
      },
      latestValue: 0.39,
      unitLabelId: 'euro-per-square-meter-per-month',
    },
    {
      dataPoint: {
        dataPointID: 'controls_emission_savings',
        tags: [
          {
            _protected: false,
            id: 1044,
            key: 'time_reference',
            source: 'user',
            value: '{"en": "Two-day average", "de": "Zwei-Tagesmittelwert"}',
          },
          {
            _protected: false,
            id: 1047,
            key: 'alternative_units',
            source: 'user',
            value: '{"en": "kg/m^2/M", "de": "kg/m^2/M"}',
          },
          {
            _protected: false,
            id: 1046,
            key: 'controls_cockpit_savings',
            source: 'user',
            value: 'emission_savings',
          },
        ],
      },
      latestValue: 0.7,
      unitLabelId: 'kilograms-per-square-meter-per-month',
    },
    {
      dataPoint: {
        dataPointID: 'controls_wellbeing_improvement',
        tags: [
          {
            _protected: false,
            id: 1049,
            key: 'alternative_units',
            source: 'user',
            value: '{"en": "% Productivity increase", "de": "% Produktivitätssteigerung"}',
          },
          {
            _protected: false,
            id: 1044,
            key: 'time_reference',
            source: 'user',
            value: '{"en": "Two-day average", "de": "Zwei-Tagesmittelwert"}',
          },
          {
            _protected: false,
            id: 1048,
            key: 'controls_cockpit_savings',
            source: 'user',
            value: 'wellbeing_improvement',
          },
        ],
      },
      latestValue: -14,
      unitLabelId: 'percent',
    },
    {
      dataPoint: {
        dataPointID: 'AIC-1-WeatherPrediction-OutdoorTemperature-AI0',
        tags: [
          {
            _protected: false,
            id: 1039,
            key: 'time_reference',
            source: 'user',
            value: '{"de": "24h-Mittelwert", "en": "24h average"}',
          },
          {
            _protected: false,
            id: 1038,
            key: 'alternative_description',
            source: 'user',
            value: '{"en": "Prediction of outdoor temperature", "de": "Vorhersage der Außentemperatur"}',
          },
          {
            _protected: false,
            id: 1037,
            key: 'alternative_units',
            source: 'user',
            value: '{"de": "°C", "en": "°C"}',
          },
          {
            _protected: false,
            id: 1034,
            key: 'controls_cockpit_current_values',
            source: 'user',
            value: 'digital_twin_forecasts',
          },
          {
            _protected: false,
            id: 1028,
            key: 'description',
            source: 'user',
            value: 'Prediction of outdoor temperature',
          },
          {
            _protected: false,
            id: 798,
            key: 'bacnet_id',
            source: 'user',
            value: '222',
          },
        ],
      },
      latestValue: 10,
      unitLabelId: 'degrees-celsius',
    },
    {
      dataPoint: {
        dataPointID: 'Dev101_Weather_Temp_ME_AI1',
        tags: [
          {
            _protected: false,
            id: 480,
            key: 'L1_analog_digital',
            source: 'user',
            value: '/digital',
          },
          {
            _protected: false,
            id: 1033,
            key: 'alternative_units',
            source: 'user',
            value: '{"en": "°C", "de": "°C"}',
          },
          {
            _protected: false,
            id: 417,
            key: 'description',
            source: 'user',
            value: 'outside air temperature W1',
          },
          {
            _protected: false,
            id: 1034,
            key: 'controls_cockpit_current_values',
            source: 'user',
            value: 'measurements',
          },
          {
            _protected: false,
            id: 476,
            key: 'L5_unit',
            source: 'user',
            value: '/temperature/Celsius',
          },
          {
            _protected: false,
            id: 966,
            key: 'L2_virtual',
            source: 'user',
            value: 'physical',
          },
          {
            _protected: false,
            id: 478,
            key: 'L4_type',
            source: 'user',
            value: '/temperature/gaseous/outdoor',
          },
          {
            _protected: false,
            id: 472,
            key: 'L3_direction',
            source: 'user',
            value: '/input',
          },
          {
            _protected: false,
            id: 1032,
            key: 'alternative_description',
            source: 'user',
            value: '{"en": "Outdoor temperature", "de": "Außentemperatur"}',
          },
        ],
      },
      latestValue: 8,
      unitLabelId: 'degrees-celsius',
    },
    {
      dataPoint: {
        dataPointID: 'Dev202_HC1_Valve_Y_AO1',
        tags: [
          {
            _protected: false,
            id: 1042,
            key: 'controls_cockpit_current_values',
            source: 'user',
            value: 'commands',
          },
          {
            _protected: false,
            id: 1041,
            key: 'alternative_units',
            source: 'user',
            value: '{"en": "%", "de": "%"}',
          },
          {
            _protected: false,
            id: 1040,
            key: 'alternative_description',
            source: 'user',
            value: '{"en": "Valve opening", "de": "Ventilöffnung"}',
          },
        ],
      },
      latestValue: 25,
      unitLabelId: 'percent',
    },
  ]
}
