<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { NavigationGuardNext, onBeforeRouteUpdate, RouteLocationNormalized, useRouter } from 'vue-router'
import { getGradientNumberForIndex } from '@/utils/helpers/controls'
import AppCountBadge from '@/components/CountBadge.vue'
import CockpitNudge from '@/components/CockpitNudge.vue'
import ControlsAlgorithmCard from '@/views/AIControls/ControlsAlgorithmCard.vue'
import ControlsAppSelector from '@/views/AIControls/ControlsAppSelector.vue'
import MasonryGrid from '@/components/MasonryGrid.vue'
import PageHeader from '@/components/PageHeader.vue'
import StatusControlsButton from './StatusControlsButton.vue'
import { storeToRefs } from 'pinia'
import { useAlgorithmsStore, AlgorithmActivityCounts } from '@/stores/views/AIControls/algorithms'
import { useI18n } from 'vue-i18n'
import useIsReadonly from '@/composables/useIsReadOnly'
import { useStore } from 'vuex'
import { useAppStore } from '@/stores/app'
import { useSelectControlsApp } from './Composables/useSelectControlsApp'
import { GlobalControlsStatus } from '@/vuex/ai_controls/types'
import useIsStrabagDemoUser from '@/composables/useIsStrabagDemoUser'
import AlgorithmInfoDialog from './AlgorithmInfoDialog.vue'

const { t } = useI18n()
const { isReadOnly } = useIsReadonly()
const algorithmsStore = useAlgorithmsStore()
const { algorithms, isLoading } = storeToRefs(algorithmsStore)
const store = useStore()
const router = useRouter()
const isAlgorithmDialogOpen = ref(false)
const selectedAlgorithm = ref<AlgorithmActivityCounts | null>(null)

onMounted(async () => {
  await fetchAlgorithmsAndAppsCount()
})

onBeforeRouteUpdate(async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  if (to.params.project && from.params.project && to.params.project !== from.params.project) {
    await fetchAlgorithmsAndAppsCount()
  }
  next()
})

async function fetchAlgorithmsAndAppsCount () {
  algorithmsStore.clearAlgorithms()
  await store.dispatch('ai_controls/fetchAndSetControlsAppsStatus')
  await useAlgorithmsStore().fetchAllAlgorithmsActivities()
}

const controlsStatus = computed(() => {
  return store.getters['ai_controls/getControlsStatus'] as GlobalControlsStatus
})

const showCockpitNudge = computed(() => {
  return store.state.ai_controls.showCockpitNudge
})

// #region CONTROL APPS SELECTOR
const { debouncedSearchApps, fetchMoreControlsApps, selectControlsApp, apps, search } = useSelectControlsApp()
const appStore = useAppStore()
// #endregion

const navigateToAppDetails = (sampleAppId: string) => {
  return router.resolve({
    name: 'ai-controls-app-id',
    params: {
      project: appStore.projectId,
      appId: sampleAppId,
    },
  })
}

const getAlgorithmDescription = (activeApps: number, inactiveApps: number) => {
  if (activeApps <= 0 && inactiveApps <= 0) {
    return t('no_set_up_apps')
  } else {
    return undefined
  }
}

const openAlgorithmDialog = (algorithm: AlgorithmActivityCounts) => {
  selectedAlgorithm.value = algorithm
  isAlgorithmDialogOpen.value = true
}

const closeAlgorithmDialog = () => {
  selectedAlgorithm.value = null
  isAlgorithmDialogOpen.value = false
}
</script>

<template>
  <div
    class="layout-wrapper"
    :style="[showCockpitNudge ? {'overflow': 'hidden'} : {}]"
  >
    <div :class="{'cockpitNudgeBlur': showCockpitNudge}">
      <PageHeader
        title-key="links.meta.title.ai_controls"
      >
        <ControlsAppSelector
          v-if="!useIsStrabagDemoUser()"
          v-model:search="search"
          class="tw-ml-auto"
          :selected-controls-app="null"
          :show-apps-state="false"
          :select-first-item="false"
          :control-apps="apps"
          :project-id="appStore.projectId"
          :fetch-more-controls-apps="fetchMoreControlsApps"
          @search-controls-apps="debouncedSearchApps"
          @select-controls-app="selectControlsApp"
        >
          <template #item-prepend="{ item }">
            <v-icon
              start
              size="small"
              :color="item.raw.custom?.icon.color"
            >
              {{ item.raw.custom?.icon.name }}
            </v-icon>
          </template>
          <template #item-action="{ item }">
            <v-btn
              variant="text"
              class="tw-ml-auto tw-p-0 tw-uppercase text-neutral-darken1"
            >
              <span class="tw-mr-4">{{ item.raw.custom?.algorithm }}</span>
              <v-icon color="neutral-darken4">
                fa:fal fa-arrow-right-long
              </v-icon>
            </v-btn>
          </template>
        </ControlsAppSelector>
        <StatusControlsButton
          v-if="!isReadOnly && !useIsStrabagDemoUser()"
          class="tw-ml-2"
          :status="controlsStatus"
        />
      </PageHeader>
      <div
        v-if="isLoading"
        class="tw-grid tw-gap-6 tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 xl:grid-cols-4"
      >
        <ControlsAlgorithmCard
          v-for="index in 9"
          :key="index"
        />
      </div>
      <MasonryGrid v-else>
        <ControlsAlgorithmCard
          v-for="(algorithm, index) of algorithms"
          :key="index"
          :algorithm="algorithm.algorithm"
          :gradient-nr="getGradientNumberForIndex(index)"
          truncate-description
          :is-active="algorithm.appsActivityCounts.active > 0"
          :to="algorithm.sampleAppId && !useIsStrabagDemoUser() ? navigateToAppDetails(algorithm.sampleAppId) : undefined"
          :description="getAlgorithmDescription(algorithm.appsActivityCounts.active, algorithm.appsActivityCounts.inactive)"
        >
          <template #title-indicator>
            <div class="tw-flex tw-gap-2 tw-flex-wrap">
              <v-btn
                v-if="algorithm.appsActivityCounts.active <= 0 && algorithm.appsActivityCounts.inactive <= 0"
                icon
                width="24"
                height="24"
                class="bg-transparent"
                rounded="rounded-circle"
                target="_blank"
                @click.stop="openAlgorithmDialog(algorithm)"
              >
                <v-icon
                  size="14"
                  color="neutral-darken1"
                >
                  fa:far fa-info-circle
                </v-icon>
              </v-btn>
              <AppCountBadge
                v-if="algorithm.appsActivityCounts.active > 0"
                :count="algorithm.appsActivityCounts.active"
                :is-active="true"
                :tooltip-text="t('active_apps')"
                data-testid="active-apps-badge"
              />
              <AppCountBadge
                v-if="algorithm.appsActivityCounts.inactive > 0"
                :count="algorithm.appsActivityCounts.inactive"
                :is-active="false"
                :tooltip-text="t('inactive_apps')"
                data-testid="inactive-apps-badge"
              />
            </div>
          </template>
        </ControlsAlgorithmCard>
      </MasonryGrid>
    </div>
    <AlgorithmInfoDialog
      v-if="isAlgorithmDialogOpen"
      :algorithm="selectedAlgorithm"
      :value="isAlgorithmDialogOpen"
      @algorithm-info-dialog-close="closeAlgorithmDialog"
    />
    <CockpitNudge
      :name="t('links.meta.title.ai_controls')"
      :title="t('nudge_title')"
      :value="showCockpitNudge"
    />
  </div>
</template>

<style lang="sass" scoped>
.layout-wrapper
  font-feature-settings: 'cv01' on, 'ss01' on, 'ss04' on
.cockpitNudgeBlur
  -webkit-filter: blur(2px)
  -moz-filter: blur(2px)
  -o-filter: blur(2px)
  -ms-filter: blur(2px)
  filter: blur(2px)
</style>

<i18n locale="de">
{
  "nudge_title": "Wir übernehmen die automatische Kontrolle des Systems!",
  "active_apps": "Aktive Apps",
  "inactive_apps": "Inaktive Apps",
  "no_set_up_apps": "Für diesen Steuerungsalgorithmus sind derzeit noch keine Apps eingerichtet."
}
</i18n>

<i18n locale="en">
{
  "nudge_title": "We take over automatic control of the system!",
  "active_apps": "active apps",
  "inactive_apps": "inactive apps",
  "no_set_up_apps": "This controls algorithm does not have any apps set up at the moment."
}
</i18n>
