<script setup lang="ts">
import { ref } from 'vue'
import type { Samplerate } from '@/vuex/data_points_view/types'
import type { SelectItem } from '@/utils/types'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

interface Props {
  disabled: boolean;
  value: string;
}

withDefaults(defineProps<Props>(), {
  disabled: false,
  value: 'auto',
})

const emit = defineEmits<{
  (e: 'update:value', value: SelectItem['value']): void;
}>()

const items = ref<SelectItem<Samplerate>[]>([
  {
    title: t('samplerate_auto') as string,
    value: 'auto',
  },
  {
    title: t('samplerate_1h') as string,
    value: '1h',
  },
  {
    title: t('samplerate_15m') as string,
    value: '15m',
  },
])

function onChangeHandler (value: SelectItem['value']) {
  emit('update:value', value)
}
</script>

<template>
  <v-select
    :model-value="value"
    data-testid="samplerate-select"
    density="compact"
    :disabled="disabled"
    hide-details
    :items="items"
    :label="t('samplerate')"
    variant="outlined"
    @update:model-value="onChangeHandler"
  />
</template>

<i18n locale="de">
{
  "samplerate": "Auflösung"
}
</i18n>
<i18n locale="en">
{
  "samplerate": "Samplerate"
}
</i18n>
