import { DatapointListItemData, DatapointTableItem } from '@/vuex/datapoints/types'
import { DataPointWithContext, Node, Tag, TagAssociation } from '@aedifion.io/aedifion-api'
import { DoubleChipItem } from '@/utils/types'
import { FilterSelectItem } from '@/components/FilterSelect/types'
import i18n from '@/i18n'
import { TagGroups } from '@/vuex/tags/types'

/**
 * Returns a description tag. Tags with source = bacnet ar prioritized.
 * @param tags A list of associated tags.
 * @returns Single tag with key = description or null, if no fitting description tag was found.
 */
export const getDescriptionTag = (tags: TagAssociation[]): TagAssociation|null => {
  const descriptionTags: Tag[] = tags.filter((tag: TagAssociation) => tag.key === 'description')
  if (descriptionTags.length === 0) return null
  let resultTag: TagAssociation|null = null
  descriptionTags.forEach((tag: TagAssociation) => {
    if (resultTag === null || tag.source?.toLowerCase() === 'bacnet' || (tag.source?.toLowerCase() === 'user' && resultTag.source?.toLowerCase() !== 'bacnet')) {
      resultTag = tag
    }
  })
  return resultTag
}

export type GroupedTagsAndTagKeys = { tagGroups: TagGroups; tagKeys: string[] }

export const groupTagsByKey = (tags: Tag[]): GroupedTagsAndTagKeys => {
  const tagGroups: TagGroups = new Map()
  const tagKeys: string[] = []
  tags.forEach((tag: Tag) => {
    const keyInMap: string[]|undefined = tagGroups.get(tag.key)
    if (keyInMap) {
      if (tag.value !== undefined) keyInMap.push(tag.value)
    } else {
      if (tag.value !== undefined) tagGroups.set(tag.key, [tag.value])
      else tagGroups.set(tag.key, [])
      tagKeys.push(tag.key)
    }
  })

  return { tagGroups, tagKeys }
}

/**
 * Compares two tags first by key and, if the keys are identical, by their values.
 * @param a first tag to be compared
 * @param b second tag to be compared
 * @returns A negative number if a occurs before b, a positive number if b occurs before a, and 0 if they are equal
 */
export const compareTags = (a: Tag, b: Tag): number => {
  const collator = new Intl.Collator(i18n.global.locale.value)
  const keyResult = collator.compare(a.key, b.key)
  if (keyResult === 0) {
    return collator.compare(a.value!, b.value!)
  } else {
    return keyResult
  }
}

/**
 * Selects tags to be displayed in a list, ie. the datapoints list or the datapoints overview. Description tags will be ignored.
 * @param descriptionTag The tag that represents the description of a datapoint.
 * @param tags The tag associations of the same datapoint.
 * @returns Array of tags. At max. three tags will be returned.
 */
export const getTagSelectionForListDisplay = (datapoint: DatapointListItemData|DatapointTableItem): Tag[] => {
  const result: Tag[] = []

  if (datapoint?.writable) {
    result.push({
      id: -1,
      key: i18n.global.t('data_points_view.tags.writable') as string,
      value: i18n.global.t('data_points_view.tags.yes') as string,
    })
  }

  const tags: TagAssociation[]|undefined = datapoint?.tags
  if (tags === undefined) return result

  const MAX_VISIBLE_TAGS = 3

  for (let i = 0; i < tags.length && result.length < MAX_VISIBLE_TAGS; i++) {
    if (tags[i].key === 'objtype' && tags[i].source === 'bacnet' && tags[i].value !== '') {
      result.push(tags[i])
    }
    if (result.length === MAX_VISIBLE_TAGS) break
  }
  return result.sort(compareTags)
}

export const datapointIsWritable = (datapoint: DataPointWithContext): boolean => {
  return 'setpoint_min_value' in datapoint && 'setpoint_max_value' in datapoint
}

/**
 * Adds a tag to a list of tags, if the tag list does not contain a new tag with the same tag-key and tag-value as the new tag.
 * @param tag The tag to be added.
 * @param tagsList The list of tags, to add the new tag to.
 * @returns New tagsList with the additional tag.
 */
export const addTagToTagsList = (tag: Tag, tagsList?: Array<Tag|TagAssociation>): Array<Tag|TagAssociation> => {
  const result = tagsList ?? []
  let found = false
  for (const tagsListItem of result) {
    if ((tagsListItem.key === tag.key) && (tagsListItem.value === tag.value)) {
      found = true
      break
    }
  }
  if (!found) {
    result.push(tag)
  }
  return result
}

/**
 * Removes the first occurrence of a tag, specified by the passed tagID, from the given tagsList, if tags with the passed tagID were found in the tagsList.
 * @param tagID The ID of the tag to be removed.
 * @param tagsList The list of tags from which the tag should be removed, if found by its ID.
 * @returns New tagsList which has removed the first tag with the passed tagID.
 */
export const removeTagFromTagsList = (tagID: number, tagsList?: Array<Tag|TagAssociation>): Array<Tag|TagAssociation> => {
  const result = tagsList ?? []
  result.forEach((tag: Tag, index: number): (TagAssociation | Tag)[] | void => {
    if (tag.id === tagID) {
      result.splice(index, 1)
      return result
    }
  })
  return result
}

/**
 * Updates tags in the passed tags list if it finds tags with the exact tag ID.
 * @param tagID ID of the tag to be updated.
 * @param newTag New tag that should replace the found tag.
 * @param tagsList List of tags in which the tag update has to happen.
 * @returns New tagsList which has updated all tags with the passed tagID.
 */
export const updateTagInTagsList = (tagID: number, newTag: Tag, tagsList?: Array<Tag|TagAssociation>): Array<Tag|TagAssociation> => {
  const result = tagsList ?? []
  result.forEach((tag: Tag, index: number) => {
    if (tag.id === tagID) {
      result.splice(index, 1, newTag)
    }
  })
  return result
}

/**
 * Creates an item for the DoubleChip component for a tag. If the tag has a label, the label's values are used, otherwise the raw values.
 * If value is not undefined, the item is assumed to be a tag's value, and thus the value is used. Otherwise the key is used.
 * @param label The label containing the display name and description, if available.
 * @param key The raw key used as a fallback.
 * @param value The raw tag used as a fallback.
 * @returns A DoubleChipItem that can be used in DoubleChip.
 */
export function createDoubleChipItem (label: Node|null, key: string, value?: string): DoubleChipItem {
  return {
    text: label?.display_name ?? value ?? key,
    tooltip: label?.description,
    value: value ?? key,
  }
}

/**
 * When filtering by tag or giving the user options to update a tag value, the values have to include both all applicable labels as well as all previously
 * used values. For example, the previously used values may include a custom value that is not based on a tag.
 * @param labels The labels that should be included.
 * @param usedValues The previously used values for the tag key that should be included.
 * @returns All labels and previously used values without duplicates.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createFullTagValueList (labels: any[]|null, usedValues: string[]|null): FilterSelectItem[] {
  let result: Array<FilterSelectItem> = []
  if (labels && labels.length > 0) {
    result = labels.map(label => {
      return {
        title: label.display_name,
        value: label.id,
      }
    })
  }
  if (usedValues !== null && usedValues.length > 0) {
    for (const usedValue of usedValues) {
      if (!result.some((item: FilterSelectItem) => { return item.value === usedValue })) {
        result.push({
          title: usedValue,
          value: usedValue,
        })
      }
    }
  }
  result.sort((left: FilterSelectItem, right: FilterSelectItem) => left.title.toLowerCase().localeCompare(right.title.toLowerCase()))
  return result
}
