<template>
  <v-app>
    <Splash :show="showSplashScreen" />
    <Navigation />
    <Notifications
      group="requests"
      position="top right"
      style="top: 60px"
      width="500"
      ignore-duplicates
    >
      <template
        #body="props"
      >
        <v-alert
          :class="`bg-${props.item.type}-lighten4 text-${props.item.type}-darken2 mt-2 mr-3 text-body-1`"
          closable
          variant="outlined"
          :type="props.item.type"
        >
          <span v-html="props.item.text" />
        </v-alert>
      </template>
    </Notifications>
    <cookie-consent-dialog
      :value="showCookieManager"
      @cookie-consent-dialog:close="closeCookieManager"
    />
    <v-main>
      <v-container
        class="fill-height mx-auto pa-10 main-container align-start"
        fluid
      >
        <router-view
          @cookie-manager:open="openCookieManager"
        />
      </v-container>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import CookieConsentDialog from '@/views/App/CookieConsentDialog.vue'
import { defineComponent } from 'vue'
import Navigation from '@/views/App/Navigation/index.vue'
import { onInitialApiRequestsCompleted } from './utils/helpers/hooks'
import Splash from '@/components/Splash.vue'
import { storeCookieSelection } from './utils/helpers/cookieConsent'
import { storeToRefs } from 'pinia'
import { useUserStore } from './stores/user'

export default defineComponent({
  name: 'App',

  components: {
    CookieConsentDialog,
    Navigation,
    Splash,
  },

  setup () {
    const userStore = useUserStore()
    const { companyName } = storeToRefs(userStore)

    return {
      companyName,
    }
  },

  data () {
    return {
      showCookieManager: false as boolean,
      showSplashScreen: true as boolean,
    }
  },

  created () {
    onInitialApiRequestsCompleted(() => {
      if (this.companyName === 'aedifion GmbH') {
        // force deactivate analytics if user’s company is aedifion GmbH
        storeCookieSelection({
          analytics: false,
        })
      }
      this.showSplashScreen = false
    })
  },

  methods: {
    closeCookieManager (): void {
      this.showCookieManager = false
    },

    openCookieManager (): void {
      this.showCookieManager = true
    },
  },
})
</script>

<style lang="sass">
  html
    overflow-y: auto !important

  .vue-notification-wrapper .v-alert
    .success--text
      color: rgb(var(--v-theme-success-darken2)) !important

    .warning--text
      color: rgb(var(--v-theme-warning-darken2)) !important

    .error--text
      color: rgb(var(--v-theme-error-darken2)) !important

  .main-container
    /* Max-width: 1640 + (2 * 40) = 1720 */
    max-width: 1720px
    position: relative
</style>
