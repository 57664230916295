import { AlertsState } from './types'

export default {
  incidentAlerts: {},
  loadingIncidentAlerts: [],
  statusCounts: {},
} as AlertsState

export function resetStoreState (state: AlertsState): void {
  state.incidentAlerts = {}
  state.loadingIncidentAlerts = []
  state.statusCounts = {}
}
