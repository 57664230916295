import { OptimizationState } from './types'

export default {
  followAnalysisPeriod: false,
  loadingComponentInProject: false,
  loadingComponentsAnalysisResults: false,
  search: null,
  selectedComponentInProject: null,
  selectedComponentInProjectResults: null,
  selectedInstanceId: null,
  selectedResultId: null,
} as OptimizationState

export function resetStoreState (state: OptimizationState): void {
  state.followAnalysisPeriod = false
  state.loadingComponentsAnalysisResults = false
  state.loadingComponentInProject = false
  state.search = null
  state.selectedComponentInProject = null
  state.selectedComponentInProjectResults = null
  state.selectedInstanceId = null
  state.selectedResultId = null
}
