<script setup lang="ts">
import { computed, ref } from 'vue'
import ConfirmationDialog from '@/components/ConfirmationDialog.vue'
import { useAlgorithmsStore } from '@/stores/views/AIControls/algorithms'
import { useI18n } from 'vue-i18n'
import { useStore } from '@/vuex'
import { GlobalControlsStatus } from '@/vuex/ai_controls/types'

const APPS_RELOAD_FETCH_DELAY = 5 // seconds

interface Props {
  status: GlobalControlsStatus | null
}

const props = withDefaults(defineProps<Props>(), {
  status: null,
})

const vuexStore = useStore()
const algorithmsStore = useAlgorithmsStore()
const { t } = useI18n()

const countDownValue = ref(-1)
const showConfirmationDialog = ref(false)

const isCountDownActive = computed(() => countDownValue.value >= 0)
const confirmationDialogWidth = computed<number>(() => isCountDownActive.value ? 560 : 857)
const isOptimizationActive = computed(() => props.status === 'running')
const operation = computed(() => isOptimizationActive.value ? 'turn_controls_off' : 'turn_controls_on')
const confirmationDialogButtonText = computed<string>(() => isOptimizationActive.value ? 'actions.switch_off' : 'actions.switch_on')
const modalIcon = computed<string>(() => isOptimizationActive.value ? 'fa:fas fa-circle-check' : 'fa:fas fa-do-not-enter')
const buttonLabel = computed<string>(() => `button_text.${isOptimizationActive.value ? 'disable_apps' : 'enable_apps'}`)

const color = computed<string>(() => {
  if (props.status === 'running') {
    return 'error'
  } else if (props.status === 'ready') {
    return 'success'
  }

  return 'neutral'
})

let interval: ReturnType<typeof setInterval> | undefined

async function countdown (): Promise<void> {
  return new Promise((resolve) => {
    interval = setInterval(() => {
      countDownValue.value--
      if (interval && countDownValue.value < 0) {
        clearInterval(interval)
        resolve()
      }
    }, 1000)
  })
}

async function changeControlsState () {
  countDownValue.value = APPS_RELOAD_FETCH_DELAY

  const countdownFinished = countdown()

  await vuexStore.dispatch(
    'ai_controls/toggleControlsAppsStatus',
    !isOptimizationActive.value,
  )

  await countdownFinished
  await vuexStore.dispatch('ai_controls/fetchAndSetControlsAppsStatus')
  await algorithmsStore.fetchAllAlgorithmsActivities()
}

function handleCloseDialog () {
  showConfirmationDialog.value = false
}

function handleStatusControlButtonClick () {
  if (props.status === null) {
    return
  }

  showConfirmationDialog.value = true
}
</script>

<template>
  <div>
    <v-btn
      variant="flat"
      :color="color"
      class="ml-auto"
      data-testid="status-controls-button"
      :disabled="status === null"
      append-icon="fa-regular fa-angle-down"
      @click="handleStatusControlButtonClick"
    >
      {{ t(buttonLabel) }}
    </v-btn>
    <ConfirmationDialog
      v-model="showConfirmationDialog"
      :accept-button-color="color"
      :accept-button-text-key="confirmationDialogButtonText"
      :card-actions-position-bottom="false"
      :dialog-width="confirmationDialogWidth"
      :show-main-content="!isCountDownActive"
      show-contact-info
      :title="t(`confirmation_dialog.title.${operation}`)"
      @confirmation-dialog:accept="changeControlsState"
      @confirmation-dialog:cancel="showConfirmationDialog = false"
    >
      <div
        class="mt-2 mb-6"
        v-html="t(`confirmation_dialog.text.${operation}`)"
      />
      <template #warning>
        <div class="alert-container d-flex flex-row align-center">
          <p>{{ t(`confirmation_dialog.warning.${operation}`) }}</p>
        </div>
      </template>
      <template #alternativeContent>
        <div
          v-if="isCountDownActive"
          class="py-10"
        >
          <v-card-text
            class="text-h6 mb-8 text-center text-neutral-darken3"
          >
            {{ t(`confirmation_dialog.progress.${operation}`) }}
          </v-card-text>
          <v-progress-circular
            class="mx-auto text-center d-flex flex-column"
            :color="color"
            :rotate="-90"
            :size="133"
            :model-value="100 - (countDownValue * 100 / APPS_RELOAD_FETCH_DELAY)"
            :width="7"
          >
            <span
              class="font-weight-bold text-h3 text-black mt-n4"
              style="position: relative"
            >
              {{ countDownValue }}
              <span
                class="
                      text-subtitle-1 text-capitalize
                      text-neutral-darken2
                      seconds-text
                    "
              >
                {{ t("seconds") }}
              </span>
            </span>
          </v-progress-circular>
        </div>
        <div
          v-else
          class="d-flex flex-column justify-center align-center overflow-hidden tw-min-h-[298px]"
        >
          <v-card-text
            class="text-h6 text-neutral-darken4 tw-flex tw-flex-col tw-justify-center"
            data-cy="confirmation-dialog-done-text"
          >
            <v-icon
              class="mx-auto tw-w-full d-block mb-4"
              :color="color"
              size="32"
            >
              {{ modalIcon }}
            </v-icon>
            <span class="d-block">{{ t(`confirmation_dialog.done.${operation}`) }}</span>
          </v-card-text>
          <v-btn
            class="ml-auto remove-hover__btn tw-absolute tw-top-0 tw-right-2"
            color="neutral-darken4"
            data-cy="confirmation-dialog-close-btn"
            variant="text"
            icon
            @click="handleCloseDialog"
          >
            <v-icon>fa:fal fa-circle-xmark</v-icon>
          </v-btn>
        </div>
      </template>
    </ConfirmationDialog>
  </div>
</template>

<style lang="sass" scoped>
.seconds-text
  position: absolute
  left: 50%
  bottom: -70%
  transform: translate(-50%, -50%)
</style>

<!-- Added this eslint ignore because the locales are actually being used, but the are dynamic and the eslint plugin cannot detect that -->
<!-- eslint-disable @intlify/vue-i18n/no-unused-keys -->
<i18n lang="json" locale="de">
  {
    "button_text": {
      "disable_apps": "Apps ausschalten",
      "enable_apps": "Apps einschalten"
    },
    "confirmation_dialog": {
      "title": {
        "turn_controls_on": "Apps einschalten",
        "turn_controls_off": "Apps ausschalten"
      },
      "text": {
        "turn_controls_on": "Möchtest du <b>alle</b> Apps einschalten?",
        "turn_controls_off": "Möchtest du <b>alle</b> Apps ausschalten?"
      },
      "progress": {
        "turn_controls_on": "Die Optimierung beginnt in:",
        "turn_controls_off": "Die Optimierung wird heruntergefahren in:"
      },
      "done": {
        "turn_controls_on": "Die Optimierung wurde eingeschaltet.",
        "turn_controls_off": "Die Optimierung wurde ausgeschaltet. "
      },
      "warning": {
        "turn_controls_on": "Durch Klicken auf \"Einschalten\" beeinflusst du den Betrieb des Gebäudes. Wenn du unsicher bist, kontaktiere bitte aedifion.",
        "turn_controls_off": "Durch Klicken auf \"Ausschalten\" beeinflusst du den Betrieb des Gebäudes. Wenn du unsicher bist, kontaktiere bitte aedifion."
      }
    },
    "seconds": "Sekunden"
  }
  </i18n>
  <!-- eslint-disable @intlify/vue-i18n/no-unused-keys -->
  <i18n lang="json" locale="en">
  {
    "button_text": {
      "disable_apps": "Disable apps",
      "enable_apps": "Enable apps"
    },
    "confirmation_dialog": {
      "title": {
        "turn_controls_on": "Enable apps",
        "turn_controls_off": "Disable apps"
      },
      "text": {
        "turn_controls_on": "Do you want to enable <b>all</b> apps?",
        "turn_controls_off": "Do you want to disable <b>all</b> apps?"
      },
      "progress": {
        "turn_controls_on": "Optimization is going to start in:",
        "turn_controls_off": "Optimization shutting down in:"
      },
      "done": {
        "turn_controls_on": "Optimization ongoing!",
        "turn_controls_off": "Optimization is stopped. Local controls activated!"
      },
      "warning":{
        "turn_controls_on": "By clicking \"Switch on\", you will change the operation of the building. If you are uncertain, please contact aedifion first.",
        "turn_controls_off": "By clicking \"Switch off\", you will change the operation of the building. If you are uncertain, please contact aedifion first."
      }
    },
    "seconds": "seconds"
   }
  </i18n>
