import { AIControlsState } from './types'

export default {
  controlsStatus: null,
  dataPoints: null,
  loadingControlsStatus: false,
  loadingDataPoints: false,
  showCockpitNudge: true,
} as AIControlsState

export function resetStoreState (state: AIControlsState): void {
  state.dataPoints = null
  state.loadingDataPoints = false
  state.controlsStatus = null
  state.loadingControlsStatus = false
  state.showCockpitNudge = true
}
